import React, { useState } from "react";
import clsx from "clsx";

import { IconHidden, IconShown } from "../../icon";
import { Input, InputProps } from "../Input";
import { FormField, FormFieldProps } from "../FormField";
import "./masked-input.css";
import "text-security/text-security.css";

export type MaskedInputProps = InputProps;

export const MaskedInput = ({ disabled, ...props }: MaskedInputProps) => {
	const [isVisible, setIsVisible] = useState(false);

	const handleVisibilityToggle = () => {
		setIsVisible(!isVisible);
	};

	return (
		<Input
			{...props}
			disabled={disabled}
			type="text"
			className={clsx(!isVisible ? "password-mask" : "")}
			iconRight={
				isVisible ? (
					<IconShown
						aria-label="Hide"
						className={clsx(
							"text-form-field-icon hover:text-form-field-icon-hover",
						)}
						onClick={handleVisibilityToggle}
					/>
				) : (
					<IconHidden
						aria-label="Show"
						className={clsx(
							"text-form-field-icon hover:text-form-field-icon-hover",
						)}
						onClick={handleVisibilityToggle}
					/>
				)
			}
		/>
	);
};

export type MaskedInputFieldProps = MaskedInputProps & FormFieldProps;

export const MaskedInputField = ({
	id,
	label,
	error,
	hint,
	tooltip,
	hasError,
	disabled,
	...props
}: MaskedInputFieldProps) => (
	<FormField
		id={id}
		label={label}
		error={error}
		hint={hint}
		tooltip={tooltip}
		hasError={hasError}
		disabled={disabled}
	>
		<MaskedInput {...props} />
	</FormField>
);
