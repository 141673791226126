"use client";

import React, { useMemo } from "react";
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	type DragEndEvent,
	UniqueIdentifier,
} from "@dnd-kit/core";
import {
	restrictToVerticalAxis,
	restrictToParentElement,
} from "@dnd-kit/modifiers";
import {
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
	useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import clsx from "clsx";
import { IconDragHandler } from "../icon";

type DraggableItemProps<TData extends { id: string; index: number }> = {
	row: TData;
	children: React.ReactNode;
	actions?: React.ReactNode;
	isDisabled?: boolean;
	"data-testid"?: string;
};

export function DraggableItem<TData extends { id: string; index: number }>({
	row,
	children,
	actions,
	isDisabled,
	"data-testid": testId,
}: DraggableItemProps<TData>) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
	} = useSortable({ id: row.id, disabled: isDisabled });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			className="w-full flex justify-between px-2 gap-x-4"
			data-testid={testId}
		>
			<div
				{...attributes}
				{...listeners}
				className="w-[30px] flex items-center"
				style={{
					// eslint-disable-next-line no-nested-ternary
					cursor: isDisabled ? "not-allowed" : isDragging ? "grabbing" : "grab",
				}}
				data-testid={`${testId}-drag-handler`}
			>
				<IconDragHandler
					className={clsx(isDisabled ? "text-neutral-200" : "text-neutral-500")}
				/>
			</div>

			<div className="w-full">{children}</div>

			{actions && <div className="flex items-center">{actions}</div>}
		</div>
	);
}

type DraggableListProps<TData extends { id: string; index: number }> = {
	data: TData[];
	children:
		| React.ReactElement<DraggableItemProps<TData>>
		| React.ReactElement<DraggableItemProps<TData>>[];
	onDrag: (event: DragEndEvent) => void;
	"data-testid"?: string;
};

/**
 * Renders a list of draggable items, utilizing the `useDraggable` hook for state management.
 */

export function DraggableList<TData extends { id: string; index: number }>({
	data,
	children,
	onDrag,
	"data-testid": testId,
}: DraggableListProps<TData>) {
	const dataIds = useMemo<UniqueIdentifier[]>(
		() => data?.map(({ id }) => id),
		[data],
	);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	return (
		<div data-testid={testId}>
			<DndContext
				sensors={sensors}
				modifiers={[restrictToVerticalAxis, restrictToParentElement]}
				collisionDetection={closestCenter}
				onDragEnd={onDrag}
				data-testid={testId}
			>
				<SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
					{children}
				</SortableContext>
			</DndContext>
		</div>
	);
}
