import React, { useRef, useState } from "react";
import { format } from "date-fns";
import clsx from "clsx";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { Separator } from "@radix-ui/react-separator";
import { useOnClickOutside } from "usehooks-ts";

import "react-datepicker/dist/react-datepicker.css";

import { IconSlimChevron } from "../../icon";
import { CardFullMobileModal, useModalState } from "../../Modal";
import { Breakpoint, Hidden } from "../../utils";

export type DateRangePickerProps = {
	startDate?: Date | null;
	endDate?: Date | null;
	onChange: (dates: [startDate: Date | null, endDate: Date | null]) => void;
};

const isDateInRange = (
	date: Date,
	startDate?: Date | null,
	endDate?: Date | null,
) => {
	if (!startDate || !endDate) {
		return false;
	}
	return date >= startDate && date <= endDate;
};

export function DateRangePicker({
	startDate,
	endDate,
	onChange,
	...props
}: DateRangePickerProps) {
	const [isPickerShown, setIsPickerShown] = useState(false);

	const modalState = useModalState();

	const desktopPickerRef = useRef<HTMLDivElement>(null);
	useOnClickOutside(desktopPickerRef, () => {
		setIsPickerShown(false);
	});

	return (
		<>
			<Hidden from={Breakpoint.MD}>
				<CardFullMobileModal
					modalState={modalState}
					disclosure={
						<DateRangePickerButton
							onClick={modalState.openModal}
							startDate={startDate}
							endDate={endDate}
						/>
					}
					modalTitle="Select dates"
				>
					<StyledReactDatePicker
						startDate={startDate}
						endDate={endDate}
						onChange={([start, end]) => {
							if (start && end) {
								modalState.closeModal();
							}
							onChange([start, end]);
						}}
						{...props}
					/>
				</CardFullMobileModal>
			</Hidden>
			<Hidden to={Breakpoint.MD}>
				<div className="relative">
					<DateRangePickerButton
						onClick={() => setIsPickerShown((prev) => !prev)}
						startDate={startDate}
						endDate={endDate}
					/>
					{isPickerShown && (
						<div ref={desktopPickerRef} className="z-select absolute mt-1">
							<StyledReactDatePicker
								startDate={startDate}
								endDate={endDate}
								onChange={([start, end]) => {
									if (start && end) {
										setIsPickerShown(false);
									}
									onChange([start, end]);
								}}
								{...props}
							/>
						</div>
					)}
				</div>
			</Hidden>
		</>
	);
}

function StyledReactDatePicker({
	startDate,
	endDate,
	...props
}: ReactDatePickerProps<never, true> & {
	startDate?: Date | null;
	endDate?: Date | null;
}) {
	return (
		<ReactDatePicker
			selectsRange
			inline
			selected={startDate}
			startDate={startDate}
			endDate={endDate}
			calendarClassName="p-4 rounded-md border border-neutral-200 bg-white shadow-sm w-full md:w-auto [&_.react-datepicker\_\_month-container]:w-full [&_.react-datepicker\_\_header]:bg-white [&_.react-datepicker\_\_header]:border-neutral-100"
			renderCustomHeader={({ increaseMonth, decreaseMonth, monthDate }) => (
				<div className="mx-auto mb-4 flex max-w-[280px] items-center justify-between">
					<button type="button" onClick={decreaseMonth}>
						<IconSlimChevron fontSize={16} direction="left" />
					</button>

					<div className="text-body-default font-bold">
						{format(monthDate, "MMMM yyyy")}
					</div>

					<button type="button" onClick={increaseMonth}>
						<IconSlimChevron fontSize={16} direction="right" />
					</button>
				</div>
			)}
			weekDayClassName={() =>
				clsx(
					"h-7 w-7 sm:h-9 sm:w-9 md:h-10 md:w-10",
					"text-[14px] text-body-xs text-neutral-500 font-bold inline-flex items-center justify-center",
				)
			}
			dayClassName={(date) =>
				clsx(
					"h-7 w-7 sm:h-9 sm:w-9 md:h-10 md:w-10",
					"text-[14px] text-body-xs font-bold align-middle inline-flex items-center justify-center",
					isDateInRange(date, startDate, endDate)
						? "bg-primary-500 text-white font-bold"
						: "bg-white text-neutral-800 font-sans hover:bg-neutral-200",
					"datepicker-day-in-selecting-range:bg-primary-500 datepicker-day-in-selecting-range:text-white datepicker-day-in-selecting-range:font-bold",
				)
			}
			{...props}
		/>
	);
}

function DateRangePickerButton({
	onClick,
	startDate,
	endDate,
}: {
	onClick: () => void;
	startDate?: Date | null;
	endDate?: Date | null;
}) {
	return (
		<button
			type="button"
			onClick={(e) => {
				e.preventDefault();
				onClick();
			}}
			className="form-field focus:ring-primary h-12 w-full min-w-[230px] py-2 focus:ring-1"
		>
			<div className="flex items-center justify-between">
				<div
					className={clsx(
						"w-full truncate px-2 text-center",
						startDate ? "text-neutral-800" : "text-neutral-400",
					)}
				>
					{startDate ? format(startDate, "MMM d, yyyy") : "Start date"}
				</div>
				<Separator orientation="vertical" className="h-6 w-px bg-neutral-300" />
				<div
					className={clsx(
						"w-full truncate px-2 text-center",
						endDate ? "text-neutral-800" : "text-neutral-400",
					)}
				>
					{endDate ? format(endDate, "MMM d, yyyy") : "End date"}
				</div>
			</div>
		</button>
	);
}
