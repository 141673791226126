import React, { useState, useCallback, useEffect } from "react";
import useEmblaCarousel, { UseEmblaCarouselType } from "embla-carousel-react";
import { type EmblaOptionsType } from "embla-carousel";
import ClassNames from "embla-carousel-class-names";
import clsx from "clsx";

import { IconSlimChevron } from "../../icon";

export type CarouselProps = {
	options?: EmblaOptionsType;
	children: React.ReactNode;
	slideGap?: string;
	nextBtn?: React.FC;
	prevBtn?: React.FC;
	carouselState?: UseEmblaCarouselType;
	showArrows?: boolean;
};

export function useCarouselState(
	options?: EmblaOptionsType,
): UseEmblaCarouselType {
	const [emblaRef, embla] = useEmblaCarousel(
		{
			loop: false,
			...options,
		},
		[ClassNames()],
	);
	return [emblaRef, embla];
}

export const Carousel = ({
	children,
	options,
	slideGap = "gap-2",
	className,
	carouselState,
	nextBtn = (props) => <CarouselArrow {...props} />,
	prevBtn = (props) => <CarouselArrow {...props} />,
	showArrows = true,
	...props
}: CarouselProps & React.ComponentProps<"div">) => {
	const defaultModalState = useCarouselState(options);

	const [emblaRef, embla] = carouselState || defaultModalState;

	const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
	const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

	const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
	const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

	const onSelect = useCallback(() => {
		if (!embla) {
			return;
		}
		setPrevBtnEnabled(embla.canScrollPrev());
		setNextBtnEnabled(embla.canScrollNext());
	}, [embla]);

	useEffect(() => {
		if (!embla) {
			return;
		}
		onSelect();
		embla.on("select", onSelect);
	}, [embla, onSelect]);

	return (
		<div
			className={clsx("relative w-full overflow-hidden", className)}
			{...props}
		>
			{showArrows &&
				prevBtn({
					onClick: scrollPrev,
					direction: "left",
					disabled: !prevBtnEnabled,
				})}
			<div ref={emblaRef}>
				<div className={clsx("flex", slideGap)}>{children}</div>
			</div>
			{showArrows &&
				nextBtn({
					onClick: scrollNext,
					direction: "right",
					disabled: !nextBtnEnabled,
				})}
		</div>
	);
};

export type CarouselArrowProps = {
	direction?: "left" | "right";
};

const CarouselArrow = ({
	direction = "left",
	className,
	...props
}: CarouselArrowProps & React.ComponentProps<"button">) => (
	<button
		type="button"
		className={clsx(
			"text-primary hover:ring-primary hover:bg-primary-50 absolute top-1/2 z-20 flex h-8 w-8 -translate-y-1/2 transform items-center justify-center rounded-full bg-white text-xs opacity-100 shadow-md transition-opacity duration-150 hover:ring-1 disabled:opacity-0 md:h-10 md:w-10 md:text-base",
			direction === "left" && ["left-6"],
			direction === "right" && ["right-6 rotate-180"],
			className,
		)}
		{...props}
	>
		<IconSlimChevron />
	</button>
);

Carousel.Arrow = CarouselArrow;
