import React, { Fragment } from "react";
import { Column, RowData } from "@tanstack/react-table";

import { ColumnMeta } from "./types";

type ConditionalWrapperProps = {
	condition: boolean;
	wrapper: (children: React.ReactNode) => React.ReactNode;
	children: React.ReactNode;
};

export const ConditionalWrapper = ({
	wrapper,
	condition,
	children,
}: ConditionalWrapperProps) => <>{condition ? wrapper(children) : children}</>;

export const callIfFunction = <TArgs extends any[], TValue>(
	a: TValue | ((...args: TArgs) => TValue),
	...args: TArgs
): TValue => {
	if (a instanceof Function) {
		return a(...args);
	}

	return a;
};

type RowWrapperProps<E extends React.ElementType = React.ElementType> = {
	linkRowTo?: string | ((row: any) => string | null);
	children: React.ReactNode;
	row?: any;
	as?: E;
};

export const RowWrapper = ({
	children,
	linkRowTo,
	row,
	as,
	...props
}: RowWrapperProps) => {
	const Component = as || "a";
	const href = linkRowTo && callIfFunction(linkRowTo!, row);
	if (href) {
		return (
			<Component
				href={href}
				className="block cursor-pointer hover:bg-neutral-50 transition-colors"
				{...props}
			>
				{children}
			</Component>
		);
	}
	return <Fragment>{children}</Fragment>;
};

export function getMetaWithDefaults<
	TData extends RowData,
	TSortField extends string,
>(
	column: Column<TData>,
): Omit<Required<ColumnMeta<TData, any, TSortField>>, "sortField"> &
	ColumnMeta<TData, any, TSortField> {
	const meta = column.columnDef.meta as
		| ColumnMeta<TData, any, TSortField>
		| undefined;

	return {
		...meta,
		align: meta?.align ?? "left",
		sortable: meta?.sortable ?? false,
		truncate: meta?.truncate ?? false,
	};
}

// Weird workaround to make column widths work as expected - the default column
// width in react-table is 150, but we want it to be unset if it's not set, so
// that other columns can still specify fixed widths.
//
// Note that this means that we cannot use 150 as a column size, it will end up
// with an auto width instead.
//
// See:
// https://github.com/TanStack/table/discussions/3192#discussioncomment-3873093
export function getColumnWidth(column: Column<any>) {
	const size = column.getSize();

	return size !== 150 ? `${size}px` : undefined;
}
