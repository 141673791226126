import { useLocalStorage } from "usehooks-ts";
import { ColumnOrderState, VisibilityState } from "@tanstack/react-table";

type DataTableLocalStorage = {
	visibility?: VisibilityState;
	order?: ColumnOrderState;
};

export function useDataTableLocalStorage(key: string) {
	const [settings, setSettings] = useLocalStorage<DataTableLocalStorage>(
		`table-settings-${key}`,
		{},
	);

	return [settings, setSettings] as const;
}
