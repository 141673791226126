import React, { Fragment, useState } from "react";

import { Carousel, CarouselProps } from "../Carousel";

import { Button, ButtonVariant, ButtonSize } from "../../click/Button";

import { Modal, ModalInnerVariant, useModalState } from "../../Modal";

import { GalleryFullScreen } from "./GalleryFullScreen";

export type GalleryImageProps = {
	thumbnail: React.ReactNode;
	image: React.ReactNode;
};

type ImageGalleryProps = {
	images: GalleryImageProps[];
	className?: string;
} & Omit<CarouselProps, "children">;

export const ImageGallery = ({ images, ...props }: ImageGalleryProps) => {
	const [modalIndex, setModalIndex] = useState(0);

	const modalState = useModalState();

	return (
		<Fragment>
			<Carousel {...props}>
				{images?.map(({ image }, i) => (
					<button
						className="group relative w-full flex-shrink-0 overflow-hidden rounded-md"
						type="button"
						onClick={() => {
							setModalIndex(i);
							modalState.openModal();
						}}
					>
						<div className="bg-neutral-140 relative w-full">{image}</div>
						<div className="absolute bottom-4 left-4 hidden lg:flex">
							<Button
								as="div"
								variant={ButtonVariant.InvertedPrimary}
								size={ButtonSize.Small}
							>
								See in full screen
							</Button>
						</div>
					</button>
				))}
			</Carousel>
			<Modal
				modalState={modalState}
				innerVariant={ModalInnerVariant.Fullscreen}
			>
				<GalleryFullScreen images={images} startIndex={modalIndex} />
			</Modal>
		</Fragment>
	);
};
