import React from "react";

const SvgUpload = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			d="M31.842 11.768C30.98 7.823 27.576 5 23.602 5c-2.96 0-5.682 1.578-7.21 4.165a6.467 6.467 0 0 0-2.01-.326c-3.554 0-6.45 2.904-6.585 6.524a6.703 6.703 0 0 0-1.464.643c-3.182 1.887-4.282 6.093-2.438 9.363 1.18 2.099 3.372 3.408 5.73 3.424H19.9v5.597c0 .333.269.61.593.61a.606.606 0 0 0 .594-.61V17.519l4.994 5.133a.579.579 0 0 0 .84 0 .626.626 0 0 0 0-.862l-6.008-6.174a.586.586 0 0 0-.84 0l-6.007 6.174a.626.626 0 0 0 0 .862c.23.236.61.236.84 0l4.994-5.133v10.062H9.68c-1.954 0-3.775-1.081-4.748-2.814-1.512-2.693-.61-6.142 2.01-7.695a5.421 5.421 0 0 1 1.583-.627.609.609 0 0 0 .467-.618l-.008-.203c0-3.059 2.422-5.548 5.398-5.548.712 0 1.401.138 2.05.415.285.122.61 0 .752-.277 1.266-2.456 3.72-3.978 6.419-3.978 3.522 0 6.53 2.587 7.147 6.15.04.244.222.431.451.488 3.3.79 5.612 3.775 5.612 7.264 0 4.116-3.253 7.46-7.258 7.46h-5.461a.606.606 0 0 0-.594.61c0 .333.27.61.594.61h5.46c4.655 0 8.446-3.897 8.446-8.68 0-3.945-2.517-7.32-6.158-8.37z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgUpload;
