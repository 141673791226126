"use client";

import { useState } from "react";
import {
	ColumnDef,
	ColumnOrderState,
	RowData,
	Updater,
} from "@tanstack/react-table";

import { useDataTableLocalStorage } from "./internal";

export function useDataTableColumnOrder<TData extends RowData>(
	columns: ColumnDef<TData, any>[],
	storageKey: string,
) {
	const [storedSettings, setStoredSettings] =
		useDataTableLocalStorage(storageKey);

	const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(
		storedSettings.order ??
			columns.reduce(
				(a, c) => (c.id ? [...a, c.id] : a),
				[] as ColumnOrderState,
			),
	);

	const onColumnOrderChange = (update: Updater<ColumnOrderState>) => {
		setColumnOrder((old) => {
			if (typeof update === "function") {
				setStoredSettings({ ...storedSettings, order: update(old) });
				return update(old);
			}

			setStoredSettings({ ...storedSettings, order: update });
			return update;
		});
	};

	return {
		columnOrder,
		onColumnOrderChange,
	};
}
