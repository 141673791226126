import React from "react";
import { Accept, ErrorCode, FileRejection } from "react-dropzone";
import clsx from "clsx";
import { formatBytes } from "@jasperlabs/number-format";

import { IconFile, IconSlimCross } from "../../icon";

type FileRemoveHandler = (file: File) => void;

export type FileCardListProps = {
	files: File[];
	fileRejections: FileRejection[];
	onRemove: FileRemoveHandler;
	maxSize?: number;
	minSize?: number;
	accept?: Accept;
};

type FileCardProps = {
	file: File;
	onRemove: FileRemoveHandler;
	isRejected?: boolean;
	rejectionReason?: React.ReactNode;
};

const FileCard = ({
	file,
	onRemove,
	isRejected = false,
	rejectionReason,
}: FileCardProps) => (
	<div className="flex w-full flex-col">
		<li
			key={file.name}
			role="presentation"
			onClick={(e) => e.stopPropagation()}
			className={clsx(
				isRejected
					? "border-form-field-error bg-form-field-error"
					: "bg-neutral-5 border-neutral-200",
				"flex w-full flex-1 items-center justify-between gap-2 truncate rounded-sm border p-2",
			)}
		>
			<IconFile className="text-primary text-3xl" />
			<span className="text-body-sm text-neutral-120 w-full truncate">
				{file.name}
			</span>
			<IconSlimCross
				role="button"
				aria-label="Remove file"
				className="ml-auto stroke-neutral-400 stroke-2 text-xl hover:stroke-neutral-200"
				onClick={() => onRemove(file)}
			/>
		</li>
		{rejectionReason && (
			<p className="text-label-sm text-form-field-error mt-2">
				{rejectionReason}
			</p>
		)}
	</div>
);

function getRejectionReason(
	rejection: FileRejection,
	{
		minSize,
		maxSize,
		accept,
	}: Pick<FileCardListProps, "minSize" | "maxSize" | "accept">,
): React.ReactNode {
	// Only use the first error
	const error = rejection.errors[0];

	if (error.code === ErrorCode.FileInvalidType) {
		const acceptedTypes = accept && Object.values(accept).flat(2).join(", ");
		return accept
			? `File type is not allowed. Accepted file types are ${acceptedTypes}.`
			: "File type is not allowed.";
	}

	if (error.code === ErrorCode.FileTooLarge) {
		return maxSize
			? `File too large. Files must be smaller than ${formatBytes(maxSize)}.`
			: "File too large.";
	}

	if (error.code === ErrorCode.FileTooSmall) {
		return minSize
			? `File too small. Files must be larger than ${formatBytes(minSize)}.`
			: "File too small.";
	}

	if (error.code === ErrorCode.TooManyFiles) {
		return null;
	}

	return null;
}
export const FileCardList = ({
	files,
	fileRejections,
	onRemove,
	maxSize,
	minSize,
	accept,
}: FileCardListProps) => (
	<ul className="flex w-full flex-col items-center justify-center gap-3">
		{files.map((file) => (
			<FileCard key={file.name} file={file} onRemove={onRemove} />
		))}
		{fileRejections.map((rejection) => (
			<FileCard
				key={rejection.file.name}
				file={rejection.file}
				onRemove={onRemove}
				rejectionReason={getRejectionReason(rejection, {
					minSize,
					maxSize,
					accept,
				})}
				isRejected
			/>
		))}
	</ul>
);
