import { RowData } from "@tanstack/react-table";

// TODO: I can't figure out how to get declaration merging working across apps
// that are using this package. So for now, we'll just re-export the ColumnMeta
// type.
export interface ColumnMeta<
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
	TData extends RowData,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
	TValue,
	TSortField extends string,
> {
	/**
	 * Specifies the alignment of the cell in the column.
	 * @default "left"
	 */
	align?: "left" | "center" | "right";
	/**
	 * Sets the field to sort by when sorting this column. If not specified, the column will not be sortable.
	 */
	sortField?: TSortField;
	/**
	 * Makes the column sortable, using the column ID as the sort field.
	 * @default false
	 */
	sortable?: boolean;
	/**
	 * Truncates the text in cells in this column.
	 * @default false
	 */
	truncate?: boolean;
}

// Same model as our GraphQL APIs
export type PageInfo = {
	hasNextPage: boolean;
	totalCount: number;
	endCursor: string;
	startCursor: string;
};

export enum DataTableSortDirection {
	Asc = "ASC",
	Desc = "DESC",
}

export type DataTableSortState<TSortField extends string = string> = {
	field: TSortField;
	direction: DataTableSortDirection;
};
