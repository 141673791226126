import React from "react";
import clsx from "clsx";

const SvgWarningTriangle = ({
	className,
	...props
}: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 25 25"
		fill="none"
		className={clsx("fill-warning", className)}
		{...props}
	>
		<path d="M13.655 2.532c-.588-1.051-2.104-1.051-2.691 0L.495 20.296c-.568 1.023.17 2.293 1.346 2.293h20.918c1.175 0 1.914-1.26 1.345-2.293L13.654 2.532zm-.322 16.504h-2.056V16.98h2.056v2.056zm0-4.112h-2.056V9.44h2.056v5.485z" />
	</svg>
);

export default SvgWarningTriangle;
