import React from "react";

const SvgMinus = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3 17h34v6H3v-6z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgMinus;
