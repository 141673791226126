import React from "react";

const SvgFile = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<g clipPath="url(#File_svg__clip0)">
			<path
				d="M31.348 10.512l-4.731-4.854C26.209 5.247 25.72 5 25.149 5H11.773C10.223 5 9 6.234 9 7.797v24.765c0 1.564 1.223 2.798 2.773 2.798h17.372c1.55 0 2.773-1.234 2.773-2.798v-20.65c.082-.495-.163-1.07-.57-1.4zM25.8 6.481l4.65 4.772H25.8V6.481zm4.976 26.081c0 .905-.734 1.564-1.55 1.564H11.773c-.897 0-1.55-.74-1.55-1.564V7.797c0-.905.734-1.563 1.55-1.563h12.724v6.253h6.198v20.075h.082zM13.649 17.917h13.62v-1.234H13.65v1.234zm0 5.43h13.62v-1.234H13.65v1.235zm0 5.43h13.62v-1.233H13.65v1.234z"
				fill="currentColor"
			/>
		</g>
	</svg>
);

export default SvgFile;
