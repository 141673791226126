import React from "react";
import clsx from "clsx";

type SvgChevronProps = React.SVGProps<SVGSVGElement> & {
	/**
	 * The direction the chevron should point.
	 * @default down
	 */
	direction?: "up" | "down" | "left" | "right";
	className?: string;
};

const SvgChevron = ({
	direction = "left",
	className,
	...props
}: SvgChevronProps) => (
	<svg
		className={clsx(
			className,
			direction === "down" && "-rotate-90",
			direction === "left" && "rotate-0",
			direction === "up" && "rotate-90",
			direction === "right" && "rotate-180",
		)}
		width="1em"
		height="1em"
		viewBox="0 0 40 40"
		fill="none"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.096 20.158L28.819 5.184 25.689 2 7.836 20.158 25.69 38.316l3.13-3.183-14.723-14.975z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgChevron;
