import React from "react";

const DragHandler = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 9 18" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.16016 1.33301C8.16016 2.02051 7.59766 2.58301 6.91016 2.58301C6.22266 2.58301 5.66016 2.02051 5.66016 1.33301C5.66016 0.645508 6.22266 0.0830078 6.91016 0.0830078C7.59766 0.0830078 8.16016 0.645508 8.16016 1.33301ZM0.660156 11.333C0.660156 10.6455 1.22266 10.083 1.91016 10.083C2.59766 10.083 3.16016 10.6455 3.16016 11.333C3.16016 12.0205 2.59766 12.583 1.91016 12.583C1.22266 12.583 0.660156 12.0205 0.660156 11.333ZM1.91016 15.083C1.22266 15.083 0.660156 15.6455 0.660156 16.333C0.660156 17.0205 1.22266 17.583 1.91016 17.583C2.59766 17.583 3.16016 17.0205 3.16016 16.333C3.16016 15.6455 2.59766 15.083 1.91016 15.083ZM0.660156 6.33301C0.660156 5.64551 1.22266 5.08301 1.91016 5.08301C2.59766 5.08301 3.16016 5.64551 3.16016 6.33301C3.16016 7.02051 2.59766 7.58301 1.91016 7.58301C1.22266 7.58301 0.660156 7.02051 0.660156 6.33301ZM1.91016 0.0830078C1.22266 0.0830078 0.660156 0.645508 0.660156 1.33301C0.660156 2.02051 1.22266 2.58301 1.91016 2.58301C2.59766 2.58301 3.16016 2.02051 3.16016 1.33301C3.16016 0.645508 2.59766 0.0830078 1.91016 0.0830078ZM5.66016 11.333C5.66016 10.6455 6.22266 10.083 6.91016 10.083C7.59766 10.083 8.16016 10.6455 8.16016 11.333C8.16016 12.0205 7.59766 12.583 6.91016 12.583C6.22266 12.583 5.66016 12.0205 5.66016 11.333ZM6.91016 5.08301C6.22266 5.08301 5.66016 5.64551 5.66016 6.33301C5.66016 7.02051 6.22266 7.58301 6.91016 7.58301C7.59766 7.58301 8.16016 7.02051 8.16016 6.33301C8.16016 5.64551 7.59766 5.08301 6.91016 5.08301ZM5.66016 16.333C5.66016 15.6455 6.22266 15.083 6.91016 15.083C7.59766 15.083 8.16016 15.6455 8.16016 16.333C8.16016 17.0205 7.59766 17.583 6.91016 17.583C6.22266 17.583 5.66016 17.0205 5.66016 16.333Z"
			fill="currentColor"
		/>
	</svg>
);

export default DragHandler;
