"use client";

import { useState } from "react";
import {
	ColumnDef,
	RowData,
	Updater,
	VisibilityState,
} from "@tanstack/react-table";

import { useDataTableLocalStorage } from "./internal";

export function useDataTableColumnVisibility<TData extends RowData>(
	columns: ColumnDef<TData, any>[],
	storageKey: string,
) {
	const [storedSettings, setStoredSettings] =
		useDataTableLocalStorage(storageKey);

	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(
		storedSettings.visibility ??
			columns.reduce((a, c) => (c.id ? { ...a, [c.id]: true } : a), {}),
	);

	const onColumnVisibilityChange = (update: Updater<VisibilityState>) => {
		setColumnVisibility((old) => {
			if (typeof update === "function") {
				setStoredSettings({ ...storedSettings, visibility: update(old) });
				return update(old);
			}

			setStoredSettings({ ...storedSettings, visibility: update });
			return update;
		});
	};

	return {
		columnVisibility,
		onColumnVisibilityChange,
	};
}
