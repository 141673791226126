import React, {
	forwardRef,
	Fragment,
	useImperativeHandle,
	useRef,
} from "react";
import clsx from "clsx";

import { IconMinus, IconPlus } from "../../icon";

import { FormField, FormFieldProps } from "../FormField";

type IncrementInputOwnProps = {
	/**
	 * The amount to increment or decrement the value by
	 * @default 1
	 */
	step?: number;
	/**
	 * The minimum value
	 * @default 0
	 */
	min?: number;
	/**
	 * The maximum value
	 */
	max?: number;
	/**
	 * Number of decimal places to round to on blur
	 * @default 0
	 */
	precision?: number;
	/**
	 * Label that prefixes the input's value (e.g. "$")
	 */
	prefixLabel?: string;
	/**
	 * Will be used to label the value in the input (e.g. "units")
	 */
	unitLabel?: string;
	hasError?: boolean;
	disabled?: boolean;
	value: string;
	onChange: (value: string) => void;
	onBlur?: (value: number) => void;
	onIncrement?: (value: string) => void;
	onDecrement?: (value: string) => void;
	"data-testid"?: string;
};

export type IncrementInputProps = Omit<
	React.ComponentPropsWithoutRef<"input">,
	keyof IncrementInputOwnProps
> &
	IncrementInputOwnProps;

const InputButton = ({
	className,
	disabled,
	...props
}: React.ComponentPropsWithoutRef<"button">) => (
	<button
		type="button"
		disabled={disabled}
		className={clsx(
			"flex h-12 items-center p-4 text-sm",
			"disabled:text-form-field-disabled disabled:cursor-not-allowed",
			"border-form-field-default disabled:border-form-field-disabled rounded-form-field border border-solid",
			"disabled:bg-form-field-disabled bg-white hover:bg-neutral-50 active:bg-neutral-50",
			className,
		)}
		{...props}
	/>
);

export const IncrementInput = forwardRef<HTMLInputElement, IncrementInputProps>(
	(
		{
			id,
			hasError,
			disabled,
			value,
			onChange,
			onBlur,
			onIncrement,
			onDecrement,
			step = 1,
			min = 0,
			max,
			precision = 0,
			prefixLabel,
			unitLabel,
			...props
		},
		forwardedRef,
	) => {
		const innerRef = useRef<HTMLInputElement>(null!);

		useImperativeHandle(forwardedRef, () => innerRef.current);

		const canIncrement =
			typeof max !== "undefined" ? parseFloat(value) + step <= max : true;
		const canDecrement =
			typeof min !== "undefined" ? parseFloat(value) - step >= min : true;

		const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
			onChange(e.target.value);
		};

		const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
			if (e.target.value === "" || Number.isNaN(parseFloat(e.target.value))) {
				onChange((0).toFixed(precision));
				onBlur?.(parseFloat((0).toFixed(precision)));
				return;
			}

			onChange(parseFloat(e.target.value).toFixed(precision));
			onBlur?.(parseFloat(parseFloat(e.target.value).toFixed(precision)));
		};

		const handleIncrement = () => {
			if (!canIncrement) {
				return;
			}

			const incrementedValue = (parseFloat(value) + step)
				.toFixed(precision)
				.toString();

			onChange(incrementedValue);
			onIncrement?.(incrementedValue);
		};

		const handleDecrement = () => {
			if (!canDecrement) {
				return;
			}

			const decrementedValue = (parseFloat(value) - step)
				.toFixed(precision)
				.toString();

			onChange(decrementedValue);
			onDecrement?.(decrementedValue);
		};

		return (
			<div className="rounded-form-field flex w-full items-center">
				<InputButton
					tabIndex={0}
					onClick={handleDecrement}
					disabled={disabled || !canDecrement}
					className="rounded-r-none border-r-0"
					aria-label="Decrement"
				>
					<IconMinus />
				</InputButton>

				<div
					tabIndex={0}
					role="textbox"
					onKeyDown={() => {
						innerRef.current?.focus();
					}}
					onClick={() => {
						innerRef.current?.focus();
					}}
					className={clsx(
						"relative flex h-12 flex-1 items-center overflow-hidden",
						"form-field rounded-none focus:ring-0",
						hasError && "form-field-error",
						disabled &&
							"bg-form-field-disabled border-form-field-disabled text-form-field-disabled cursor-not-allowed",
					)}
				>
					{prefixLabel && (
						<Fragment>
							<div className="text-body-default md:text-body-sm absolute inset-y-0 right-1/2 flex max-w-[50%] items-center pl-4 pr-1 text-neutral-500">
								{prefixLabel}
								<div className="invisible top-0 right-0">{value}</div>
							</div>
							<div className="invisible flex items-center pl-2">
								{prefixLabel}
							</div>
						</Fragment>
					)}
					<input
						{...props}
						tabIndex={-1}
						ref={innerRef}
						type="number"
						step={step}
						id={id}
						disabled={disabled}
						className={clsx(
							"text-body-default md:text-body-sm h-full w-full border-none bg-transparent focus:outline-none focus:ring-0",
							// Pad right of input to make room for unit label
							unitLabel ? "pr-[50%] text-end" : "text-center",
						)}
						value={value}
						onBlur={handleBlur}
						onChange={handleChange}
					/>
					{unitLabel && (
						<span
							className={clsx(
								"text-body-xs absolute left-1/2 ml-1 mt-[2px]",
								disabled ? "text-form-field-disabled" : "text-neutral-500",
							)}
						>
							{unitLabel}
						</span>
					)}
				</div>

				<InputButton
					tabIndex={0}
					onClick={handleIncrement}
					disabled={disabled || !canIncrement}
					className="rounded-l-none border-l-0"
					aria-label="Increment"
				>
					<IconPlus />
				</InputButton>
			</div>
		);
	},
);

export type IncrementInputFieldProps = FormFieldProps &
	Omit<React.ComponentPropsWithoutRef<"input">, keyof IncrementInputOwnProps> &
	IncrementInputOwnProps;

export const IncrementInputField = forwardRef<
	HTMLInputElement,
	IncrementInputFieldProps
>(
	(
		{
			id,
			label,
			hint,
			error,
			tooltip,
			className,
			hasError,
			disabled,
			"data-testid": testID,
			...props
		},
		forwardedRef,
	) => (
		<FormField
			id={id}
			data-testid={testID}
			label={label}
			hint={hint}
			error={error}
			tooltip={tooltip}
			className={className}
			hasError={hasError}
			disabled={disabled}
		>
			<IncrementInput ref={forwardedRef} {...props} />
		</FormField>
	),
);
